<template>
  <!-- <img src="@/assets/images/cone.png" style="width: 150px" /> -->
  <h1
    style="font-size: 4rem; color: white; margin:25px 20px; text-shadow: 0px 0px 4px black; fontWeight: bolder;"
  >
    {{ appName }}
  </h1>
  <h2
    style="color: white; margin:0px 20px 10px 20px; text-shadow: 0px 0px 4px black; fontWeight: bolder;"
  >
    Coming Soon
  </h2>
  <a
    href="http://www.waafisoft.ca"
    target="_blank"
    style="color:yellow; textDecoration: none; margin: 20px auto; text-shadow: 0px 0px 4px black;"
    >Waafi Software</a
  >
</template>

<script>
export default {
  data: () => ({
    appName: process.env.VUE_APP_NAME,
  }),
}
</script>

<style>
html {
  width: 100%;
  height: 100%;
  margin: 0;
  /* background-color: rgb(1, 107, 1); */
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.5)
    ),
    url("~@/assets/images/bg.jpg");
  background-size: cover;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
}
</style>
